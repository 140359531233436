<template>
	<div>
		{{ searchKindLabel }}: {{ $route.query.term }}
		<OrderInfo
			v-for="(item, index) of partsList"
			:key="index"
			:data="item"
			:identifier="`idx-${index}__part-${item.id}`"
		/>

		<LabelList
			:title="labelListTitle"
			:list="currentProductList"
			v-if="Object.keys(currentProductList).length"
			class="mt-5"
		/>
	</div>
</template>

<script>
import OrderInfo from '@/components/OrderInfo.vue';
import LabelList from '@/components/LabelList.vue';

import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
	name: 'SearchResult',
	components: {
		OrderInfo,
		LabelList,
	},
	computed: {
		...mapState( 'main', {
			current_language: 'current_language',
			searchIds: 'searchIds',
			products: 'products',
			parts: 'parts',
			search: 'search',
		} ),
        ...mapGetters("main", {
            labelGetter:'labelGetter',
            sysLabelGetter:'sysLabelGetter',
        }),
		searchKindLabel() {
			if ( this.$route.query.type === 'roof_ids' ) return this.sysLabelGetter('search_product_by_query');
			return this.sysLabelGetter('search_parts_by_query');
		},
		labelListTitle() {
			if ( this.$route.query.type === 'roof_ids' ) return this.sysLabelGetter('entered_roof_number_matches_the_following_roof');
			return this.sysLabelGetter('this_parts_can_be_found_in_following_products');
		},
		partsList() {
			let output = [];
			if ( ['article_no', 'parts', 'roof_ids'].includes( this.$route.query.type ) ) {
				this.searchIds.forEach( item => {
					if ( this.parts[item] ) {
						let data = JSON.parse(JSON.stringify(this.parts[item]));
						output.push({
							id: item,
							...data,
							footnote: data.footnote ? this.labelGetter(data.footnote, `footnote of part ${item}`) : '',
							label: data.label ? this.labelGetter(data.label, `part ${item}`) : ''
						});
					}
				} );
			}
			return output;
		},
		currentProductList() {
			let output = {};
			let productIds = [];
			this.partsList.forEach( item => {
				productIds = Array.from( new Set( productIds.concat(item.products) ) );
			} )
			if ( this.$route.query.type === 'roof_ids' ) productIds = this.searchIds;
			productIds.forEach( id => {
				let product = this.products[id];
				output[id] = {
					_id: product._id,
					label: this.labelGetter(product.label, `product ${product._id}`),
					link: { name: 'product', params: { id } }
				};
			} )
			return output;
		},
	},
	methods: {
		...mapMutations( 'main', {
			updateSearchIds: 'updateSearchIds',
		} ),
		getSearchResult() {
			let ids = [];
			let searchTerm = this.$route.query.term.replace(/[\s-[\]{}()*+!<=:?.\\^$|#\s,]/g, '');
			if ( this.$route.query.type === 'parts' ) {
				Object.entries( this.search.parts ).forEach( item => {
                    const title = this.labelGetter(item[0], `part ${item[1]}`)
					let element = title.replace(/[\s-[\]{}()*+!<=:?.\\^$|#\s,]/g, '');
					let regex = new RegExp(searchTerm, "i");
					if ( element.match( regex ) ) ids.push( item[1] );
				} );
			}
			if ( ['article_no', 'roof_ids'].includes( this.$route.query.type ) ) {
				Object.entries( this.search[this.$route.query.type] ).forEach( item => {
					let element = item[0].replace(/[\s-[\]{}()*+!<=:?.\\^$|#\s,]/g, '');
					let regex = new RegExp(searchTerm, "i");
					if ( element.match( regex ) ) ids.push( item[1] );
				} );
			}
			this.updateSearchIds( ids );
		}
	},
	mounted() {
		if ( !this.searchIds.length && this.$route.query.term && this.$route.query.type ) {
			this.getSearchResult();
		}
	},
	watch: {
		current_language() {
			this.getSearchResult();
		},
	},
}
</script>

<style lang="sass" scoped>

</style>